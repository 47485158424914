import React from "react";

import "./ProjectCard.css";

const ProjectCard = (props) => {
  const projectName = props.projectName;
  const projectURL = props.projectURL;
  const projectDescription = props.projectDescription;
  const projectDuration = props.projectDuration;

  return (
    <div>
      <div className="projCard-grid-space">
        <a
          className="projCard"
          rel="noreferrer"
          href={projectURL}
          target="_blank"
          style={{
            backgroundImage:
              "linear-gradient(135deg, rgba(206, 159, 252,1) 0%, rgba(115, 103, 240,0.85) 100%), url(https://abhinavbhardwaj.in/projectIcon.png)",
            backdropFilter: "blur(20px) saturate(180%);",
            color: "black",
          }}
        >
          <div style={{ alignItems: "center" }}>
            <span className="projectName">{projectName}</span>
            <br />
            <span className="projectDescription">{projectDescription}</span>
            <div className="duration">{projectDuration}</div>
            {/*<div className="tags">
              <div className="tag">HTML</div>
              <div className="tag">HTML</div>
            </div>*/}
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
