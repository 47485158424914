import React from "react";

import "./SkillBar.css";

const SkillBar = (props) => {
  return (
    <div>
      {" "}
      <div className="skillBar">
        <div className="skillInfo">
          <span>{props.skillTitle}</span>
        </div>
        <div className={`progress-line ${props.skillTitle}`}>
          <span style={{ width: `${props.skillLevel}%` }}></span>
        </div>
      </div>
    </div>
  );
};

export default SkillBar;
