import React from "react";

import "./AboutMe.css";

const AboutMe = () => {
  return (
    <div className="aboutMeContainer">
      <h2 className="headingAboutMe">
        a LiTtLe <span style={{ color: "#4595eb" }}>AbOuT mE</span> . . .
      </h2>

      <div className="entire">
        <div className="aboutMeLeft left">
          I'm a Software Engineer by PROFESSION & a FullStack Developer driven
          by PASSION. Graduating with a B.Tech degree, who possess a strong
          foundation in programming, complemented by a flair for UI/UX
          Designing.
          <br />
          <br />
          Focusing on writting clean, elegant, & efficient code while designing
          interactive and visually appealing User Interfaces (UI).
        </div>

        <div className="avtar">
          <img
            src="https://raw.githubusercontent.com/Abhinav-Bhardwaj-09/cdn/master/MyAvatars/Abhinav%20with%20AB%20Icon.gif"
            className="avtar_img"
            alt="avtar_img"
          />
        </div>

        <div className="aboutMeRight left">
          Whether I'm designing user interface or building a responsive web
          applications, I'm dedicated to transforming ideas into reality.
          <br />
          <br />
          With a commitment to continuous learning, I thrive on the process of
          conceptualizing designs, translating them into code, and delivering
          seamless User Experiences (UX).
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
