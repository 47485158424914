import React from "react";

import "./CertificateBar.css";

const CertificateBar = (props) => {
  return (
    <div className="temp">
      <div className="certificateBar">
        <div className="certificateInfo">
          <span>{props.certificateTitle}</span>
        </div>
        <div className="certificateDetails">
          <span className="certificateIssuedBy">
            {props.certificateIssuedBy}
          </span>

          <span className="certificateIssueDate">
            {props.certificateIssueDate}
          </span>
        </div>

        {/*<div className={`${props.certificateTitle}`}>
                  <span style={{ width: `${props.certificateLevel}%` }}></span>
  </div>*/}
      </div>
    </div>
  );
};

export default CertificateBar;
