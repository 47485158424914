import React from "react";
import GitHubCalendar from "react-github-calendar";

import "./GithubContributionGraph.css";

const GitContributionGraph = () => {
  return (
    <div className="contributionGraphPart">
      <h2 className="headingheadingGit">
        my <span style={{ color: "#4595eb" }}>GitHub </span>contributions
      </h2>

      <div className="githubgraph">
        <GitHubCalendar
          username="Abhinav-Bhardwaj-09"
          blockSize={16}
          blockMargin={5}
        />{" "}
      </div>
    </div>
  );

  //
  //
  //
  // <GitHubCalendar
  //   username="Abhinav-Bhardwaj-09"
  //   blockSize={16}
  //   blockMargin={5}
  //   transformTotalCount={false}
  //   labels={{
  //     totalCount: "{{count}} contributions in the last 12 months",
  //   }}
  // />;
  //
  //
  //   var theme = ["#EEEEEE", "#F78A23", "#F87D09", "#AC5808", "#7B3F06"];

  //   const date = new Date();
  //   let day = date.getDate();
  //   let month = date.getMonth() + 1;
  //   let year = date.getFullYear();
  //   let currentDate = `${year}-${month}-${day}`;

  //   var weekaNmes = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //   var monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sept",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   return (
  //     <div style={{ backgoundColor: "white" }}>
  //       <Calendar
  //         username="Abhinav-Bhardwaj-09"
  //         theme={theme}
  //         until={currentDate}
  //         weekNames={weekaNmes}
  //         monthNames={monthNames}
  //       />
  //     </div>
  //   );
};

export default GitContributionGraph;
