import React from "react";

import SkillBar from "./UI component/SkillBar";

import "./Skills.css";

const Skills = () => {
  return (
    <div className="skillsContainer">
      <h2 className="headingSkillsContainer">
        My <span style={{ color: "#4595eb" }}>Skill</span> Set
      </h2>

      <div className="skill-bars">
        <SkillBar skillTitle="C" skillLevel="80" />
        <SkillBar skillTitle="Embedded_C" skillLevel="65" />
        <SkillBar skillTitle="Python" skillLevel="75" />
        <SkillBar skillTitle="Java" skillLevel="80" />

        <SkillBar skillTitle="HTML" skillLevel="85" />
        <SkillBar skillTitle="CSS" skillLevel="65" />

        <SkillBar skillTitle="PHP" skillLevel="80" />
        <SkillBar skillTitle="MySQL" skillLevel="70" />

        <SkillBar skillTitle="JavaScript" skillLevel="50" />
        <SkillBar skillTitle="jQuery" skillLevel="40" />

        <SkillBar skillTitle="ReactJS" skillLevel="50" />
        <SkillBar skillTitle="Express" skillLevel="55" />
        <SkillBar skillTitle="MongoDB" skillLevel="70" />

        <SkillBar skillTitle="Figma" skillLevel="35" />
        <SkillBar skillTitle="Git" skillLevel="30" />
      </div>
    </div>
  );
};

export default Skills;
