import React from "react";
import { Helmet } from "react-helmet";

import Header from "./components/Header";
import MainBody from "./pages/MainBody";
import Footer from "./components/Footer";

import "./App.css";

const App = () => {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />

        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="icon" type="image/png" sizes="16x16" href="/logo16.png" />
        <link rel="icon" type="image/png" sizes="24x24" href="/logo24.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/logo32.png" />
        <link rel="icon" type="image/png" sizes="48x48" href="/logo48.png" />
        <link rel="icon" type="image/png" sizes="64x64" href="/logo64.png" />
        <link rel="icon" type="image/png" sizes="128x128" href="/logo128.png" />
        <link rel="icon" type="image/png" sizes="180x180" href="/logo180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/logo192.png" />
        <link rel="icon" type="image/png" sizes="512x512" href="/logo512.png" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo128.png" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="author" content="Abhinav Bhardwaj" />

        <meta
          name="image"
          property="og:image"
          content="%PUBLIC_URL%/displayImage.png"
        />

        <title>Abhinav Bhardwaj</title>

        <base target="_blank" href="https://abhinavbhardwaj.in/" />

        <meta
          name="description"
          content="Portfolio designed using ReactJs, HTML, Bootstrap and CSS."
        />
        <meta
          name="keywords"
          content="Abhinav Bhardwaj, Associate Software Engineer, Associate Software Engineer in Mphasis, B.Tech from GLA University"
        />
      </Helmet>
      <Header />
      <MainBody />
      <Footer />
    </div>
  );
};

export default App;
