import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footerPart">
      <i>Made with</i> &nbsp;
      <div
        className="beating-heart"
        style={{ transform: "scale(1.17015) translateZ(0px)" }}
      >
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.42857 2.4375C6.87556 1.03025 5.32629 0 3.71429 0C1.6153 0 0 1.56975 0 3.65625C0 6.52356 3.13073 8.74088 7.42857 13C11.7264 8.74088 14.8571 6.52356 14.8571 3.65625C14.8571 1.56975 13.2418 0 11.1429 0C9.52921 0 7.98159 1.03025 7.42857 2.4375Z"
            fill="red"
          ></path>
        </svg>
      </div>
      &nbsp; &nbsp; <i>by</i> &nbsp;{" "}
      <a
        href="https://www.instagram.com/_abhinav_._rajput_"
        target="_blank"
        rel="noreferrer"
        className="abhinavBhardwaj beating-name"
      >
        <b>
          <i>Abhinav Bhardwaj</i>
        </b>
      </a>
      <br />
      <i>All rights reserved © 2024</i>
    </div>
  );
};

export default Footer;
