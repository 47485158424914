import React from "react";

import "./Projects.css";
import ProjectCard from "./UI component/ProjectCard";

const Projects = () => {
  return (
    <div className="projectsContainer">
      <h2 className="headingProjectsContainer">
        Few live <span style={{ color: "#4595eb" }}>Projects</span>
      </h2>
      <section className="projCards-wrapper">
        <ProjectCard
          projectName="CSV Data Generator"
          projectURL="https://csv-data-generator-ui.vercel.app"
          projectDuration="Aug 2024"
          projectDescription="Web App to generate CSV Data File"
        />

        <ProjectCard
          projectName="Trailer Browsing App for a Movie Theatre"
          projectURL="https://www.figma.com/proto/opBETqZJIDlk6erOE3Va7V/Trailer-Browsing-App?node-id=1%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=60%3A3"
          projectDuration="Dec 2021"
          projectDescription="Protoype of an App"
        />

        <ProjectCard
          projectName="Ready reckoner"
          projectURL="https://ready-reckoner.vercel.app"
          projectDuration="Jun 2021"
          projectDescription="Simple Calculator"
        />
        <ProjectCard
          projectName="ToDo App"
          projectURL="https://todo-app-live.vercel.app"
          projectDuration="Jun 2021"
          projectDescription="Simple ToDo App"
        />

        <ProjectCard
          projectName="UI Color Treasure"
          projectURL="https://ui-color-treasure.vercel.app"
          projectDuration="Feb 2021"
          projectDescription="A treasure of UI Colors"
        />

        <ProjectCard
          projectName="Old Portfolio"
          projectURL="https://abhinav-bhardwaj-09.herokuapp.com"
          projectDuration="Jan 2021"
          projectDescription="First Personalized Portfolio"
        />
      </section>
    </div>
  );
};

export default Projects;
