import React from "react";

import AboutMe from "../components/AboutMe";
import Projects from "../components/Projects";
import Skills from "../components/Skills";
import Certifications from "../components/Certifications";
import GitContributionGraph from "../components/GitContributionGraph";

import "./MainBody.css";

const MainBody = () => {
  return (
    <div className="main-body">
      <AboutMe />
      <GitContributionGraph />
      <Skills />
      <Certifications />
      <Projects />
      <br />
    </div>
  );
};

export default MainBody;
