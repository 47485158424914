import React from "react";

import CertificateBar from "./UI component/CertificateBar";

import "./Certifications.css";

const Certifications = () => {
  return (
    <div className="certificateContainer">
      <h2 className="headingCertificateContainer">
        My <span style={{ color: "#4595eb" }}>Certifications</span>
      </h2>

      <div className="certificate-bars">
        {/* Associate Cloud Engineer | 08/24 */}
        <a
          href="https://www.credly.com/badges/1b6b4c3c-3ed0-4424-9571-ecf535797b17"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Google Cloud Certified : Associate"
            certificateIssuedBy="From Google Cloud"
            certificateIssueDate="08/2024"
          />
        </a>

        {/* Generative AI Fundamentals | 06/24 */}
        <a
          href="https://www.cloudskillsboost.google/public_profiles/620a9450-e520-4eb3-9da7-7a32333fb962/badges/9669297"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Generative AI Fundamentals"
            certificateIssuedBy="From Google Cloud Skills Boost"
            certificateIssueDate="06/2024"
          />
        </a>

        {/* Preparing for Your Associate Cloud Engineer Journey | 04/24 */}
        <a
          href="https://www.udemy.com/certificate/UC-AE104F38-9B72-4A3D-BD54-A821D3971434/"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="GCP - Associate Cloud Engineer"
            certificateIssuedBy="From Udemy"
            certificateIssueDate="04/2024"
          />
        </a>

        {/* Preparing for Your Associate Cloud Engineer Journey | 02/24 */}
        <a
          href="https://www.cloudskillsboost.google/public_profiles/99e9fca2-05e7-4ea3-8dc2-afd50cf54e80/badges/8177442"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Preparing for Associate Cloud Engineer Journey"
            certificateIssuedBy="From Google Cloud Skills Boost"
            certificateIssueDate="02/2024"
          />
        </a>

        {/* Introduction to Responsible AI | 01/24 */}
        <a
          href="https://www.cloudskillsboost.google/public_profiles/620a9450-e520-4eb3-9da7-7a32333fb962/badges/7905311"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Introduction to Responsible Artificial Intelligence"
            certificateIssuedBy="From Google Cloud Skills Boost"
            certificateIssueDate="01/2024"
          />
        </a>

        {/* Introduction to Large Language Models | 12/23 */}
        <a
          href="https://www.cloudskillsboost.google/public_profiles/620a9450-e520-4eb3-9da7-7a32333fb962/badges/6476941"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Introduction to Large Language Models"
            certificateIssuedBy="From Google Cloud Skills Boost"
            certificateIssueDate="12/2023"
          />
        </a>

        {/* Introduction to Java || Certificate of Excellence | 11/23 */}
        <a
          href="https://certificate.codingninjas.com/view/5fc3e40ed7d86188"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Introduction to Java || Certificate of Excellence"
            certificateIssuedBy="From CodingNinjas"
            certificateIssueDate="11/2023"
          />
        </a>

        {/* Introduction to Generative AI | 10/23 */}
        <a
          href="https://www.cloudskillsboost.google/public_profiles/620a9450-e520-4eb3-9da7-7a32333fb962/badges/5637252"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Introduction to Generative AI"
            certificateIssuedBy="From Google Cloud Skills Boost"
            certificateIssueDate="10/2023"
          />
        </a>

        {/* Build Wireframes and Low-Fidelity Prototypes | 12/21 */}
        <a
          href="https://coursera.org/share/51f0b9edf52337b150d705e52cb6229f"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Build Wireframes and Low-Fidelity Prototypes"
            certificateIssuedBy="From Coursera"
            certificateIssueDate="12/2021"
          />
        </a>

        {/* Start UX Design Process: Empathize, Define Ideate | 10/21 */}
        <a
          href="https://coursera.org/share/582f64f21b48d85c03787ec6a0b65e5c"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Start UX Design Process: Empathize, Define &amp; Ideate"
            certificateIssuedBy="From Coursera"
            certificateIssueDate="10/2021"
          />
        </a>

        {/* Foundations of User Experience (UX) Design | 06/21 */}
        <a
          href="https://coursera.org/share/ee8fa281efef4b9a75fead9528a0d4db"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Foundations of User Experience (UX) Design"
            certificateIssuedBy="From Coursera"
            certificateIssueDate="06/2021"
          />
        </a>

        {/* M001-MongoDB Basics | 06/21 */}
        <a
          href="https://university.mongodb.com/course_completion/dee84223-5040-4841-8c00-09a6dd24cb92"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="M001-MongoDB Basics"
            certificateIssuedBy="From MongoDB University"
            certificateIssueDate="06/2021"
          />
        </a>

        {/* Java Tutorial for Complete Beginners | 01/21 */}
        <a
          href="https://www.udemy.com/certificate/UC-ca493c82-b354-4fc4-82a6-046bdef77c27/"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Java Tutorial for Complete Beginners"
            certificateIssuedBy="From Udemy"
            certificateIssueDate="01/2021"
          />
        </a>

        {/* The MERN Fullstack Guide | 11/20 */}
        <a
          href="https://www.udemy.com/certificate/UC-4e6c25c0-d5c7-4778-bac9-2d76b70ccf03/"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="The MERN Fullstack Guide"
            certificateIssuedBy="From Udemy"
            certificateIssueDate="11/2020"
          />
        </a>

        {/* C (Advanced) | 09/20 */}
        <a
          href="https://www.hackerrank.com/certificates/4947a2c4277d"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="C (Advanced)"
            certificateIssuedBy="From HackerRank"
            certificateIssueDate="09/2020"
          />
        </a>

        {/* C (Intermediate) | 09/20 */}
        <a
          href="https://www.hackerrank.com/certificates/762d121076ab"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="C (Intermediate)"
            certificateIssuedBy="From HackerRank"
            certificateIssueDate="09/2020"
          />
        </a>

        {/* C (Basic) | 09/20 */}
        <a
          href="https://www.hackerrank.com/certificates/0bfbaa7822d5"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="C (Basic)"
            certificateIssuedBy="From HackerRank"
            certificateIssueDate="09/2020"
          />
        </a>

        {/* Machine Learning | 08/20 */}
        <a
          href="https://ict-certificates.s3.ap-south-1.amazonaws.com/15179-399310-1bf087e74edd22be.pdf"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Machine Learning"
            certificateIssuedBy="From ICT - Indian Institute of Technology, Kanpur"
            certificateIssueDate="08/2020"
          />
        </a>

        {/* Python Programming – A Practical Approach | 08/20 */}
        <a
          href="https://ict-certificates.s3.ap-south-1.amazonaws.com/15179-9350-b47d6311d87c947d.pdf"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Python Programming – A Practical Approach"
            certificateIssuedBy="From ICT - Indian Institute of Technology, Kanpur"
            certificateIssueDate="08/2020"
          />
        </a>

        {/* Python (Basic) | 06/20 */}
        <a
          href="https://www.hackerrank.com/certificates/52b765abdbb3"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Python (Basic)"
            certificateIssuedBy="From HackerRank"
            certificateIssueDate="08/2020"
          />
        </a>

        {/* Java Programming Masterclass for Software Developers | 11/19 */}
        <a
          href="https://www.udemy.com/certificate/UC-EFDDI0S3/"
          target="_blank"
          rel="noreferrer"
          className="certificateAnchor"
          style={{ textDecoration: "none" }}
        >
          <CertificateBar
            certificateTitle="Java Programming Masterclass for Software Developers"
            certificateIssuedBy="From Udemy"
            certificateIssueDate="11/2019"
          />
        </a>
      </div>
    </div>
  );
};

export default Certifications;
